import React, { useEffect, useState, useCallback, useId } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'react-slidedown/lib/slidedown.css'
import Dropdown from '../dropdown'
import { FormSelectYesNo, FormSelectYesNoFile, FormInputText, DateSelector, FormTextArea } from './formelements'
import { useDropzone } from 'react-dropzone'


const ContacteEmergencia = (props) => {

  return (<div className={'col ' + props.col}>
    <div className="t-Form-labelContainer">
      <label id="contacteEmergenciaNom" className="t-Form-label">{props.label}</label>
    </div>

    <FormInputText id="contacteEmergenciaNom" name="contacteEmergenciaNom" label="Nom i cognoms" col="col-12" value={props.values.contacteEmergenciaNom} obligatori={false} size="30" maxLength="50" onChange={props.onChanges.nom} error={props.errors.contacteEmergenciaNom} />
    <FormInputText id="contacteEmergenciaRelacio" name="contacteEmergenciaRelacio" label="Relació amb l'alumne/a" col="col-12" value={props.values.contacteEmergenciaRelacio} obligatori={false} size="30" maxLength="50" onChange={props.onChanges.relacio} error={props.errors.contacteEmergenciaRelacio} />
    <FormInputText id="contacteEmergenciaTelefon" name="contacteEmergenciaTelefon" label="Telèfon" col="col-12" value={props.values.contacteEmergenciaTelefon} obligatori={false} size="30" maxLength="50" onChange={props.onChanges.telefon} error={props.errors.contacteEmergenciaTelefon} />

  </div>)
}


export default function EnrolmentFormPersonalDataId(props) {

  /* DADES GENERALS */
  const uid = useId()
  const [errors, setErrors] = useState({});

  let maxDate = new Date("31-12-2021")
  let minDate = new Date("1-1-1970")


  /* DADES PERSONALS */
  const [dadesIdentificativesOpen, setDadesIdentificativesOpen] = useState(true);
  const toggleOpen = () => {
    setDadesIdentificativesOpen(!dadesIdentificativesOpen);
  }

  const [nom, setNom] = useState('');
  const [cognom1, setCognom1] = useState('');
  const [cognom2, setCognom2] = useState('');
  const [tipusDoc, setTipusDoc] = useState('DNI');
  const [iddocument, setIDDocument] = useState('');
  const [iddocumentFile, setIDDocumentFile] = useState();
  const [sexe, setSexe] = useState('');
  const [dataNaixement, setDataNaixement] = useState()
  const [majorEdat, setMajorEdat] = useState();
  const [llibreFamiliaFile, setLlibreFamiliaFile] = useState();
  const [comunicacioProgenitors, setComunicacioProgenitors] = useState();

  const [progenitor1Nom, setProgenitor1Nom] = useState();
  const [progenitor1IDFile, setProgenitor1IDFile] = useState();
  const [progenitor1Telefon, setProgenitor1Telefon] = useState();
  const [progenitor1Email, setProgenitor1Email] = useState();
  const [progenitor1Observacions, setProgenitor1Observacions] = useState();

  const [progenitor2Nom, setProgenitor2Nom] = useState();
  const [progenitor2IDFile, setProgenitor2IDFile] = useState();
  const [progenitor2Telefon, setProgenitor2Telefon] = useState();
  const [progenitor2Email, setProgenitor2Email] = useState();
  const [progenitor2Observacions, setProgenitor2Observacions] = useState();

  const [contacteEmergenciaNom, setContacteEmergenciaNom] = useState();
  const [contacteEmergenciaRelacio, setContacteEmergenciaRelacio] = useState();
  const [contacteEmergenciaTelefon, setContacteEmergenciaTelefon] = useState();

  const [adreca, setAdreca] = useState('');
  const [codiPostal, setCodiPostal] = useState('');
  const [poblacio, setPoblacio] = useState('');
  const [provincia, setProvincia] = useState('');
  const [pais, setPais] = useState('Espanya');
  const [telefonFix, setTelefonFix] = useState('');
  const [telefonMobil, setTelefonMobil] = useState('');


  /* DADES MEDIQUES */
  const [dadesMediquesOpen, setDadesMediquesOpen] = useState(true);
  const toggleDadesMediquesOpen = () => {
    setDadesMediquesOpen(!dadesMediquesOpen);
  }

  const [TSI, setTSI] = useState('');
  const [TSIFile, setTSIFile] = useState();
  const [nSS, setnSS] = useState('');
  const [allergies, setAllergies] = useState();
  const [allergiesFile, setAllergiesFile] = useState();
  const [allergiesObservacions, setAllergiesObservacions] = useState();
  const [malalties, setMalalties] = useState();
  const [malaltiesFile, setMalaltiesFile] = useState();
  const [malaltiesObservacions, setMalaltiesObservacions] = useState();
  const [problemesVista, setProblemesVista] = useState();
  const [problemesVistaFile, setProblemesVistaFile] = useState();
  const [problemesVistaObservacions, setProblemesVistaObservacions] = useState();
  const [problemesMobilitat, setProblemesMobilitat] = useState();
  const [problemesMobilitatFile, setProblemesMobilitatFile] = useState();
  const [problemesMobilitatObservacions, setProblemesMobilitatObservacions] = useState();
  const [medicacio, setMedicacio] = useState();
  const [medicacioFile, setMedicacioFile] = useState();
  const [medicacioObservacions, setMedicacioObservacions] = useState();

  /* DADES ACADEMIQUES */
  const [dadesAcademiquesOpen, setDadesAcademiquesOpen] = useState(true);
  const toggleDadesAcademiquesOpen = () => {
    setDadesAcademiquesOpen(!dadesAcademiquesOpen);
  }

  const [estudisAccess, setEstudisAccess] = useState('');

  /* DADES LABORALS */
  const [dadesLaboralsOpen, setDadesLaboralsOpen] = useState(true);
  const toggleDadesLaboralsOpen = () => {
    setDadesLaboralsOpen(!dadesLaboralsOpen);
  }

  const [treballant, setTreballant] = useState();
  const [hasTreballatMai, setHasTreballatMai] = useState();
  const [hasTreballatMaiSector, setHasTreballatMaiSector] = useState();
  const [treballarasDurantCurs, setTreballarasDurantCurs] = useState();
  const [treballarasDurantCursHorari, setTreballarasDurantCursHorari] = useState();
  const [creusTreballarDificultaEstudis, setCreusTreballarDificultaEstudis] = useState();


  /* VALIDA DADES PERSONALS */
  const validaNom = () => {
    return nom.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaCognom1 = () => {
    return cognom1.length > 0 ? null : "El camp Primer Cognom no pot estar buit"
  }

  const validaSexe = () => {
    return nom.length > 0 ? null : "Heu d'indicar un sexe"
  }

  const validaStringNoBuit = (camp, errormsg) => {
    return camp.length > 0 ? null : errormsg;
  }

  const validaAdreca = () => {
    return validaStringNoBuit(adreca, "El camp adreça no pot estar buit");
  }

  const validaCodiPostal = () => {
    return validaStringNoBuit(codiPostal, "El camp codi postal no pot estar buit");
  }

  const validaPoblacio = () => {
    return validaStringNoBuit(poblacio, "El camp població no pot estar buit");
  }

  const validaProvincia = () => {
    return validaStringNoBuit(provincia, "El camp provincia no pot estar buit");
  }

  const validaPais = () => {
    return validaStringNoBuit(pais, "El camp país no pot estar buit");
  }

  const validaIDDocument = () => {

    let d = iddocument.toUpperCase()

    if (d !== iddocument) setIDDocument(d)

    if (tipusDoc === "DNI") {
      return /^[0-9]{8}[A-Z]$/.test(iddocument) ? null : "El format de DNI no és vàlid."
    }

    return iddocument.length > 0 ? null : "El camp no pot estar buit."
  }

  const validaIDDocumentFile = () => {

    return iddocumentFile ? null : "El camp no pot estar buit."
  }

  const validaProgenitor1Nom = () => {
    return progenitor1Nom.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaProgenitor1Telefon = () => {
    return progenitor1Telefon.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaProgenitor1Email = () => {
    return progenitor1Email.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaProgenitor1Observacions = () => {
    return progenitor2Observacions.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaProgenitor2Nom = () => {
    return progenitor1Nom.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaProgenitor2Telefon = () => {
    return progenitor2Telefon.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaProgenitor2Email = () => {
    return progenitor2Email.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaProgenitor2Observacions = () => {
    return progenitor2Observacions.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaComunicacioProgenitors = () => {
    if (majorEdat) {
      return comunicacioProgenitors.length > 0 ? null : "Valor obligatori"
    }

    return null
  }

  const valida = () => {

    let error = {}

    error.nom = validaNom();

    handleErrors('nom', validaNom())

    error.cognom1 = validaCognom1();

    handleErrors('cognom1', error)

    handleErrors('dataNaixement', validaDataNaixement())

    error.pais = validaPais();

    handleErrors('pais', error)

    error.provincia = validaProvincia();

    handleErrors('provincia', error)

    error.poblacio = validaPoblacio();

    handleErrors('poblacio', error)

    error.codiPostal = validaCodiPostal();

    handleErrors('codiPostal', error)

    error.adreca = validaAdreca();

    handleErrors('adreca', error)

    //errors.tipusDoc = validaTipusDoc(tipusDoc)

    error.iddocument = validaIDDocument()

    error.iddocumentFile = validaIDDocumentFile()

    handleErrors('iddocumentFile', error)

    error.sexe = validaSexe(sexe)

    setErrors(error)

    console.log('errors', errors)

  }

  // VALIDA 
  useEffect(() => {

    let error = {}

    error.iddocument = validaIDDocument();

    handleErrors('iddocument', error)

  }, [iddocument, tipusDoc])

  useEffect(() => {

    let error = {}

    console.log('valida Nom')
    error.nom = validaNom();

    handleErrors('nom', error)

  }, [nom])


  useEffect(() => {

    let error = {}

    error.pais = validaPais();

    handleErrors('pais', error)

    error.provincia = validaProvincia();

    handleErrors('provincia', error)

    console.log('errors adreça: ')

    error.poblacio = validaPoblacio();

    handleErrors('poblacio', error)

    error.codiPostal = validaCodiPostal();

    handleErrors('codiPostal', error)

    error.adreca = validaAdreca();

    handleErrors('adreca', error)


  }, [adreca, codiPostal, poblacio, provincia, pais])

  useEffect(() => {

    let error = {}

    console.log('valida data naixement')
    error.dataNaixement = validaDataNaixement();

    handleErrors('dataNaixement', error)

    let dadesPersonals = { ...props.dadesPersonals }

    dadesPersonals.dataNaixement = dataNaixement;

    props.onChange(dadesPersonals)

  }, [dataNaixement])


  const validaDataNaixement = () => {

    let temps = new Date(dataNaixement)

    //console.log('temps0',temps)

    temps.setFullYear(temps.getFullYear() + 15) // no valid per gent que no faci els 16 dins l'any

    //console.log('temps1',temps)

    let timestamp = new Date().getTime() - temps.getTime()

    //console.log('time: ',timestamp,new Date().getTime(),'-',temps.getTime()) 

    if (timestamp <= 0)  // menor  16
    {
      return "Data incorrecta"
    }

    temps.setFullYear(temps.getFullYear() + 2)

    if (dataNaixement) setMajorEdat(new Date().getTime() - temps.getTime() > 0 ? true : false)

    // data ok
    return null;
    // return /^[0-3][0-9]\/[0-1][0-9]\/[0-9]{4}$/.test(dataNaixement) ? null : "El format de la data no és vàlid."
  }


  const handleErrors = (nom, error) => {

    console.log('error', nom, error)

    setErrors(error)

  }

  function DropZone(props) {
    const onDrop = useCallback(acceptedFiles => {
      // Do something with the files

      handleErrors(props.id, { iddocumentFile: null })

      let file = acceptedFiles[0];

      if (file) {
        if (!/^image/.test(file.type)) {
          setIDDocumentFile(null)
          return handleErrors(props.id, { [props.id]: "Tipus d'arxiu no vàlid" })
        }

        props.onChange(file)

        console.log(acceptedFiles[0].name) // size type
      }

    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    let trash = (<FontAwesomeIcon icon={faTrash} fontSize="16" />)

    return (
      <div className="col">
        <div className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs" id={props.id + '_CONTAINER'}>
          <div className="t-Form-labelContainer">
            <label for={props.id} id={props.id + '_LABEL'}
              className="t-Form-label">{props.label} <small>{props.labelSmall}</small></label>
          </div>
          <div className="t-Form-inputContainer">
            <div className="t-Form-itemWrapper">
              <div {...getRootProps()} style={{ width: '100%' }}>
                <input {...getInputProps()} />
                {isDragActive && <p>Drop the files here ...</p>}
                {!isDragActive && (<div className="dropZone"> {props.file ? props.file.name : props.labelDrop} </div>)}
              </div>
            </div>
            <span id={props.id + '_ERROR'} className="a-Form-error">{props.error}</span>
          </div>
        </div>
      </div>)
  }


  return (

    <div className="container">
      <div className="row">
        <div className="col col-12 ">
          <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">
            <div className="t-Region-header">
              <div className="t-Region-headerItems  t-Region-headerItems--controls">
                <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={toggleOpen}>
                  {dadesIdentificativesOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                  {!dadesIdentificativesOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                </button>
              </div>
              <div className="t-Region-headerItems t-Region-headerItems--title">
                <h2 className="t-Region-title a-Collapsible-heading">Dades personals</h2>
              </div>
              <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
            </div>
            <div className="t-Region-bodyWrap">

              <Dropdown open={dadesIdentificativesOpen}>
                <div className="t-Region-body a-Collapsible-content">

                  <div className="container">
                    <div className="row">
                      <div className="col col-12 ">
                        <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                          <div className="t-Region-bodyWrap">
                            <div className="t-Region-body">
                              <div className="container">
                                <div className="row">
                                  <div className="col col-4 ">
                                    <div
                                      className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs" id="tipusDoc_CONTAINER">
                                      <div className="t-Form-labelContainer">
                                        <label for="tipusDoc" id="tipusDoc_LABEL"
                                          className="t-Form-label">Tipus document:</label>
                                      </div>
                                      <div className="t-Form-inputContainer">
                                        <div className="t-Form-itemWrapper">

                                          <select id="tipusDoc" name="tipusDoc" className="selectlist" size="1" onChange={(e) => setTipusDoc(e.target.value)}>
                                            <option value="DNI" selected="selected">DNI - Document Nacional d'Identitat </option>
                                            <option value="NIE">NIE - Número d'Identificació d'Estranger</option>
                                            <option value="PASS">PASS - Passaport/Document d'un país de la comunitat Europea</option>
                                          </select></div>
                                        <span className="a-Form-error">{errors.tipusDoc}</span>
                                      </div>
                                    </div>
                                  </div>


                                  <FormInputText id="iddocument" name="iddocument" col="col-4" label="Número document" obligatori={true} size="20" maxLength="15" value={iddocument} onChange={setIDDocument} error={errors.iddocument} />

                                  <DropZone id="iddocumentFile" file={iddocumentFile} onChange={setIDDocumentFile} label="Adjuntar document" labelSmall="(ambdues cares)" labelDrop="adjuntar imatge" error={errors.iddocumentFile} />

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                    <div className="row">
                      <div className="col col-12 ">
                        <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding margin-top-sm">

                          <div className="t-Region-bodyWrap">
                            <div className="t-Region-body">
                              <div className="container">
                                <div className="row">

                                  <FormInputText id="nom" name="nom" col="col-3" label="Nom" value={nom} obligatori={true} size="30" maxLength="50" onChange={setNom} valida={validaNom} error={errors.nom} />

                                  <FormInputText id="cognom1" name="cognom1" col="col-3" label="Primer cognom" value={cognom1} obligatori={true} size="30" maxLength="50" onChange={setCognom1} valida={validaCognom1} error={errors.cognom1} />

                                  <FormInputText id="cognom2" name="cognom2" col="col-3" label="Segon cognom" value={cognom2} obligatori={false} size="30" maxLength="50" onChange={setCognom2} error={errors.cognom2} />

                                  <div className="col col-3 ">
                                    <div className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs" id="sexe_CONTAINER">
                                      <div className="t-Form-labelContainer">
                                        <label for="sexe" id="sexe_LABEL" className="t-Form-label">Sexe: </label>
                                        <span className="t-Form-required">
                                          <span className="a-Icon">*</span>
                                        </span>
                                      </div>

                                      <div className="t-Form-inputContainer">
                                        <div className="t-Form-itemWrapper">
                                          <select id="sexe" name="sexe" className="selectlist" size="1" onChange={(e) => setSexe(e)}>
                                            <option value="" selected="selected"></option>
                                            <option value="D">Dona</option>
                                            <option value="H">Home</option>
                                          </select></div>
                                        <span id="sexe_ERROR" className="a-Form-error">{errors.sexe}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                <div className="row">

                                  <div className="col col-3 ">
                                    <div id="dataNaixement" className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">
                                      <div className="t-Form-labelContainer">
                                        <label for="dataNaixement" id="dataNaixement_LABEL" className="t-Form-label">Data Naixement (dd/mm/aaaa): </label>
                                        <span className="t-Form-required">
                                          <span className="a-Icon">*</span>
                                        </span>
                                      </div>

                                      <div className="t-Form-inputContainer">
                                        <div className="t-Form-itemWrapper">
                                          <DateSelector selected={dataNaixement} onChange={setDataNaixement} minDate={minDate} maxDate={maxDate} />
                                        </div>

                                        <span id="dataNaixement_ERROR" className="a-Form-error">{errors.dataNaixement}</span>
                                      </div>
                                    </div>
                                  </div>

                                  {majorEdat === true && (<React.Fragment>

                                    <FormSelectYesNo id="autoritzoComunicacioProgenitors"
                                      name="autoritzoComunicacioProgenitors"
                                      col="col-5"
                                      label="Autoritzo que els meus progenitors/tutors legals puguin rebre comunicats de l’institut."
                                      obligatori={true}
                                      handleChange={(e) => setComunicacioProgenitors(e)} />


                                    <ContacteEmergencia col="col-4"
                                      label="Telèfon en cas d'emergència"
                                      values={{ nom: contacteEmergenciaNom, relacio: contacteEmergenciaRelacio, telefon: contacteEmergenciaTelefon }}
                                      onChanges={{ nom: setContacteEmergenciaNom, relacio: setContacteEmergenciaRelacio, telefon: setContacteEmergenciaTelefon }}
                                      errors={errors} />

                                  </React.Fragment>)
                                  }


                                  {majorEdat === false &&

                                    (<React.Fragment>
                                      <DropZone id="llibreFamiliaFile" file={llibreFamiliaFile} onChange={setLlibreFamiliaFile} label="Llibre de família" labelSmall="(plana de pare/mare i alumne/a)" labelDrop="adjuntar imatge" error={errors.llibreFamiliaFile} />
                                      <div className="col col-3"><small>En cas de alumnat estranger sense llibre de família, adjuntar documentació pertinent.</small></div>


                                      <div className="row">
                                        <div className="col col-12" >
                                          <div className="t-Form-labelContainer">
                                            <label id="progenitor1Nom_LABEL" for="progenitor1Nom" className="t-Form-label">Dades dels progenitors / Tutors legals</label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">

                                        <div className="col col-12" >

                                          <div className="col col-4">
                                            <div className="t-Form-labelContainer">
                                              <label id="progenitor1Nom_LABEL" for="progenitor1Nom" className="t-Form-label">Progenitor 1*</label>
                                            </div>

                                            <FormInputText id="progenitor1Nom" name="progenitor1Nom" col="col-12" label="Nom i cognoms" value={progenitor1Nom} obligatori={true} size="30" maxLength="50" onChange={setProgenitor1Nom} error={errors.progenitor1Nom} />
                                            <DropZone id="progenitor1IDFile" file={progenitor1IDFile} onChange={setProgenitor1IDFile} label="DNI/NIE/Passaport" labelDrop="adjuntar imatge" error={errors.progenitor1IDFile} />
                                            <FormInputText id="progenitor1Telefon" name="progenitor1Telefon" col="col-12" label="Telèfon" value={progenitor1Telefon} obligatori={true} size="30" maxLength="50" onChange={setProgenitor1Telefon} error={errors.progenitor1Telefon} />
                                            <FormInputText id="progenitor1Email" name="progenitor1Email" col="col-12" label="E-mail" value={progenitor1Email} obligatori={true} size="30" maxLength="50" onChange={setProgenitor1Email} error={errors.progenitor1Email} />
                                            <FormTextArea id="progenitor1Observacions" name="progenitor1Observacions" label="Horari de contacte / Observacions" value={progenitor1Observacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setProgenitor1Observacions} error={errors.progenitor1Observacions} />
                                          </div>

                                          <div className="col col-4">
                                            <div className="t-Form-labelContainer">
                                              <label id="progenitor2Nom_LABEL" for="progenitor2Nom" className="t-Form-label">Progenitor 2</label>
                                            </div>

                                            <FormInputText id="progenitor2Nom" name="progenitor2Nom" col="col-12" label="Nom i cognoms" value={progenitor2Nom} obligatori={false} size="30" maxLength="50" onChange={setProgenitor2Nom} error={errors.progenitor2Nom} />
                                            <DropZone id="progenitor2IDFile" file={progenitor2IDFile} onChange={setProgenitor2IDFile} label="DNI/NIE/Passaport" labelDrop="adjuntar imatge" error={errors.progenitor2IDFile} />
                                            <FormInputText id="progenitor2Telefon" name="progenitor2Telefon" col="col-12" label="Telèfon" value={progenitor1Telefon} obligatori={false} size="30" maxLength="50" onChange={setProgenitor2Telefon} error={errors.progenitor2Telefon} />
                                            <FormInputText id="progenitor2Email" name="progenitor2Email" col="col-12" label="E-mail" value={progenitor2Email} obligatori={false} size="30" maxLength="50" onChange={setProgenitor2Email} error={errors.progenitor2Email} />
                                            <FormTextArea id="progenitor2Observacions" name="progenitor2Observacions" label="Horari de contacte / Observacions" value={progenitor2Observacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setProgenitor2Observacions} error={errors.progenitor2Observacions} />

                                          </div>

                                          <ContacteEmergencia col="col-4"
                                            label="Telèfon en cas d'emergència"
                                            values={{ nom: contacteEmergenciaNom, relacio: contacteEmergenciaRelacio, telefon: contacteEmergenciaTelefon }}
                                            onChanges={{ nom: setContacteEmergenciaNom, relacio: setContacteEmergenciaRelacio, telefon: setContacteEmergenciaTelefon }}
                                            errors={errors} />

                                        </div>

                                      </div>
                                    </React.Fragment>)}

                                </div>

                                <div className="row">

                                  <FormInputText id="telefonfix" name="telefonfix" col="col-3" label="Telèfon fix alumne/a" value={telefonFix} obligatori={false} size="10" maxLength="10" onChange={setTelefonFix} error={errors.telefonFix} />
                                  <FormInputText id="telefonmobil" name="telefonmobil" col="col-3" label="Telèfon mòbil alumne/a" value={telefonMobil} obligatori={false} size="10" maxLength="10" onChange={setTelefonMobil} error={errors.telefonMobil} />

                                </div>

                                <div className="row">

                                  <FormInputText id="adreca" name="adreca" col="col-5" label="Adreça habitual" value={adreca} obligatori={true} size="30" maxLength="50" onChange={setAdreca} error={errors.adreca} />
                                  <FormInputText id="codipostal" name="codipostal" col="col-1" label="Codi postal" value={codiPostal} obligatori={true} size="10" maxLength="10" onChange={setCodiPostal} error={errors.codiPostal} />

                                </div>
                                <div className="row">

                                  <FormInputText id="poblacio" name="poblacio" col="col-3" label="Població" value={poblacio} obligatori={true} size="20" maxLength="50" onChange={setPoblacio} error={errors.poblacio} />
                                  <FormInputText id="provincia" name="provincia" col="col-3" label="Provincia" value={provincia} obligatori={true} size="10" maxLength="10" onChange={setProvincia} error={errors.provincia} />
                                  <FormInputText id="pais" name="pais" col="col-3" label="País" value={pais} obligatori={true} size="10" maxLength="10" onChange={setPais} error={errors.pais} />

                                </div>

                              </div>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                </div>
              </Dropdown>
            </div>
          </div>

          <button onClick={valida}>test valida</button>
        </div>
      </div>




      {/* DADES MEDIQUES */}
      <div className="container">
        <div className="row">
          <div className="col col-12 ">
            <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">
              <div className="t-Region-header">
                <div className="t-Region-headerItems  t-Region-headerItems--controls">
                  <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={toggleDadesMediquesOpen}>
                    {dadesMediquesOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                    {!dadesMediquesOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                  </button>
                </div>
                <div className="t-Region-headerItems t-Region-headerItems--title">
                  <h2 className="t-Region-title a-Collapsible-heading">Dades mèdiques</h2>
                </div>
                <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
              </div>
              <div className="t-Region-bodyWrap">

                <Dropdown open={dadesMediquesOpen}>
                  <div className="t-Region-body a-Collapsible-content">

                    <div className="container">
                      <div className="row">
                        <div className="col col-12 ">
                          <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                            <div className="t-Region-bodyWrap">
                              <div className="t-Region-body">
                                <div className="container">
                                  <div className="row">


                                    <FormInputText id="TSI" name="TSI" col="col-3" label="Targeta Sanitaria Individual (TSI)" obligatori={true} size="20" maxLength="15" value={TSI} onChange={setTSI} error={errors.codiTSI} />

                                    <DropZone id="TSIFile" file={TSIFile} onChange={setTSIFile} label="Adjuntar TSI" labelDrop="adjuntar document" error={errors.TSIFile} />

                                    <div className="col col-1">&nbsp;</div>

                                    <FormInputText id="nSS" name="nSS" col="col-3" label="Nº Seguretat Social" obligatori={false} size="20" maxLength="15" value={nSS} onChange={setnSS} error={errors.nSS} />

                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="allergies"
                                        name="allergies"
                                        col="col-2"
                                        label="Al·lèrgies o intoleràncies"
                                        obligatori={false}
                                        value={allergies}
                                        file={allergiesFile}
                                        handleChange={(e) => setAllergies(e.target.value)} />

                                      {allergies === "1" && <FormTextArea id="allergiesObservacions" name="allergiesObservacions" col="col-5" label="Observacions" value={allergiesObservacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setAllergiesObservacions} error={errors.allergiesObservacions} />}
                                      {allergies === "1" && <DropZone id="allergiesFile" file={allergiesFile} onChange={setAllergiesFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.allergiesFile} />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="malalties"
                                        name="malalties"
                                        col="col-2"
                                        label="Malalties greus"
                                        obligatori={false}
                                        value={malalties}
                                        file={malaltiesFile}
                                        handleChange={(e) => setMalalties(e.target.value)} />

                                      {malalties === "1" && <FormTextArea id="malaltiesObservacions" name="malaltiesObservacions" col="col-5" label="Observacions" value={malaltiesObservacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setMalaltiesObservacions} error={errors.malaltiesObservacions} />}
                                      {malalties === "1" && <DropZone id="malaltiesFile" file={malaltiesFile} onChange={setMalaltiesFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.malaltiesFile} />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="problemesVista"
                                        name="problemesVista"
                                        col="col-2"
                                        label="Problemes de vista/oïda?"
                                        obligatori={false}
                                        value={problemesVista}
                                        file={problemesVistaFile}
                                        handleChange={(e) => setProblemesVista(e.target.value)} />

                                      {problemesVista === "1" && <FormTextArea id="problemesVistaObservacions" name="problemesVistaObservacions" col="col-5" label="Observacions" value={problemesVistaObservacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setProblemesVistaObservacions} error={errors.problemesVistaObservacions} />}
                                      {problemesVista === "1" && <DropZone id="problemesVistaFile" file={problemesVistaFile} onChange={setProblemesVistaFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.problemesVistaFile} />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="problemesMobilitat"
                                        name="problemesMobilitat"
                                        col="col-2"
                                        label="Problemes de mobilitat?"
                                        obligatori={false}
                                        value={problemesMobilitat}
                                        file={problemesMobilitatFile}
                                        handleChange={(e) => setProblemesMobilitat(e.target.value)} />

                                      {problemesMobilitat === "1" && <FormTextArea id="problemesMobilitatObservacions" name="problemesMobilitatObservacions" col="col-5" label="Observacions" value={problemesMobilitatObservacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setProblemesMobilitatObservacions} error={errors.problemesMobilitatObservacions} />}
                                      {problemesMobilitat === "1" && <DropZone id="problemesMobilitatFile" file={problemesMobilitatFile} onChange={setProblemesMobilitatFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.problemesMobilitatFile} />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="prensMedicacio"
                                        name="prensMedicacio"
                                        col="col-2"
                                        label="Prens alguna medicació?"
                                        obligatori={false}
                                        value={medicacio}
                                        file={medicacioFile}
                                        handleChange={(e) => setMedicacio(e.target.value)} />

                                      {medicacio === "1" && <FormTextArea id="medicacioObservacions" name="medicacioObservacions" col="col-5" label="Observacions" value={medicacioObservacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setMedicacioObservacions} error={errors.medicacioObservacions} />}
                                      {medicacio === "1" && <DropZone id="medicacioFile" file={medicacioFile} onChange={setMedicacioFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.medicacioFile} />}

                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>


                </Dropdown>
              </div>
            </div>

          </div>
        </div>



      </div>

      {/* DADES MEDIQUES */}
      {/*
                  <div className="container">
                    <div className="row">
                      <div className="col col-12 ">
                        <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">
                          <div className="t-Region-header">
                            <div className="t-Region-headerItems  t-Region-headerItems--controls">
                              <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={toggleDadesAcademiquesOpen}>
                                {dadesAcademiquesOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                                {!dadesAcademiquesOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                              </button>
                            </div>
                            <div className="t-Region-headerItems t-Region-headerItems--title">
                              <h2 className="t-Region-title a-Collapsible-heading">Dades acadèmiques</h2>
                            </div>
                            <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
                          </div>
                          <div className="t-Region-bodyWrap">

                            <Dropdown open={dadesAcademiquesOpen}>
                              <div className="t-Region-body a-Collapsible-content">

                                <div className="container">
                                  <div className="row">
                                    <div className="col col-12 ">
                                      <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                                        <div className="t-Region-bodyWrap">
                                          <div className="t-Region-body">
                                            <div className="container">
                                              <div className="row">


                                                <FormInputText id="TSI" name="TSI" col="col-3" label="Targeta Sanitaria Individual (TSI)" obligatori={true} size="20" maxLength="15" value={TSI} onChange={setTSI} error={errors.codiTSI} />

                                                <DropZone id="TSIFile" file={TSIFile} onChange={setTSIFile} label="Adjuntar TSI" labelDrop="adjuntar document" error={errors.TSIFile} />

                                              <div className="col col-1">&nbsp;</div>

                                                <FormInputText id="nSS" name="nSS" col="col-3" label="Nº Seguretat Social" obligatori={false} size="20" maxLength="15" value={nSS} onChange={setnSS} error={errors.nSS} />

                                              </div>

                                              <div className="row">
                                                <div className="col col-12 ">

                                                  <FormSelectYesNo id="allergies"
                                                    name="allergies"
                                                    col="col-2"
                                                    label="Al·lèrgies o intoleràncies"
                                                    obligatori={false}
                                                    value={allergies}
                                                    file={allergiesFile}
                                                    handleChange={(e) => setAllergies(e.target.value)} />

                                                  {allergies === "1" && <FormTextArea id="allergiesObservacions" name="allergiesObservacions" col="col-5" label="Observacions" value={allergiesObservacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setAllergiesObservacions} error={errors.allergiesObservacions} />}
                                                  {allergies === "1" && <DropZone id="allergiesFile" file={allergiesFile} onChange={setAllergiesFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.allergiesFile} />}

                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col col-12 ">

                                                  <FormSelectYesNo id="malalties"
                                                    name="malalties"
                                                    col="col-2"
                                                    label="Malalties greus"
                                                    obligatori={false}
                                                    value={malalties}
                                                    file={malaltiesFile}
                                                    handleChange={(e) => setMalalties(e.target.value)} />

                                                  {malalties === "1" && <FormTextArea id="malaltiesObservacions" name="malaltiesObservacions" col="col-5" label="Observacions" value={malaltiesObservacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setMalaltiesObservacions} error={errors.malaltiesObservacions} />}
                                                  {malalties === "1" && <DropZone id="malaltiesFile" file={malaltiesFile} onChange={setMalaltiesFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.malaltiesFile} />}

                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col col-12 ">

                                                  <FormSelectYesNo id="problemesVista"
                                                    name="problemesVista"
                                                    col="col-2"
                                                    label="Problemes de vista/oïda?"
                                                    obligatori={false}
                                                    value={problemesVista}
                                                    file={problemesVistaFile}
                                                    handleChange={(e) => setProblemesVista(e.target.value)} />

                                                  {problemesVista === "1" && <FormTextArea id="problemesVistaObservacions" name="problemesVistaObservacions" col="col-5" label="Observacions" value={problemesVistaObservacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setProblemesVistaObservacions} error={errors.problemesVistaObservacions} />}
                                                  {problemesVista === "1" && <DropZone id="problemesVistaFile" file={problemesVistaFile} onChange={setProblemesVistaFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.problemesVistaFile} />}

                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col col-12 ">

                                                  <FormSelectYesNo id="problemesMobilitat"
                                                    name="problemesMobilitat"
                                                    col="col-2"
                                                    label="Problemes de mobilitat?"
                                                    obligatori={false}
                                                    value={problemesMobilitat}
                                                    file={problemesMobilitatFile}
                                                    handleChange={(e) => setProblemesMobilitat(e.target.value)} />

                                                  {problemesMobilitat === "1" && <FormTextArea id="problemesMobilitatObservacions" name="problemesMobilitatObservacions" col="col-5" label="Observacions" value={problemesMobilitatObservacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setProblemesMobilitatObservacions} error={errors.problemesMobilitatObservacions} />}
                                                  {problemesMobilitat === "1" && <DropZone id="problemesMobilitatFile" file={problemesMobilitatFile} onChange={setProblemesMobilitatFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.problemesMobilitatFile} />}

                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col col-12 ">

                                                  <FormSelectYesNo id="prensMedicacio"
                                                    name="prensMedicacio"
                                                    col="col-2"
                                                    label="Prens alguna medicació?"
                                                    obligatori={false}
                                                    value={medicacio}
                                                    file={medicacioFile}
                                                    handleChange={(e) => setMedicacio(e.target.value)} />

                                                  {medicacio === "1" && <FormTextArea id="medicacioObservacions" name="medicacioObservacions" col="col-5" label="Observacions" value={medicacioObservacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setMedicacioObservacions} error={errors.medicacioObservacions} />}
                                                  {medicacio === "1" && <DropZone id="medicacioFile" file={medicacioFile} onChange={setMedicacioFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.medicacioFile} />}

                                                </div>
                                              </div>

                                            </div>

                                          </div>
                                        </div>
                                      </div>

                                    </div>

                                  </div>
                                </div>
                              </div>


                            </Dropdown>
                          </div>
                        </div>

                      </div>
                    </div>



                  </div>
                  */}



      {/* DADES LABORALS */}
      <div className="container">
        <div className="row">
          <div className="col col-12 ">
            <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">
              <div className="t-Region-header">
                <div className="t-Region-headerItems  t-Region-headerItems--controls">
                  <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={toggleDadesLaboralsOpen}>
                    {dadesLaboralsOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                    {!dadesLaboralsOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                  </button>
                </div>
                <div className="t-Region-headerItems t-Region-headerItems--title">
                  <h2 className="t-Region-title a-Collapsible-heading">Dades laborals</h2>
                </div>
                <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
              </div>
              <div className="t-Region-bodyWrap">

                <Dropdown open={dadesLaboralsOpen}>
                  <div className="t-Region-body a-Collapsible-content">

                    <div className="container">
                      <div className="row">
                        <div className="col col-12 ">
                          <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                            <div className="t-Region-bodyWrap">
                              <div className="t-Region-body">
                                <div className="container">
                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="treballant"
                                        name="treballant"
                                        col="col-2"
                                        label="Actualment estàs treballant?"
                                        obligatori={false}
                                        value={treballant}
                                        handleChange={(e) => setTreballant(e.target.value)} />

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="treballatMai"
                                        name="treballatMai"
                                        col="col-2"
                                        label="Has treballat mai?"
                                        obligatori={false}
                                        value={hasTreballatMai}
                                        handleChange={(e) => setHasTreballatMai(e.target.value)} />

                                      {hasTreballatMai === "1" && <FormTextArea id="hasTreballatMaiSector" name="hasTreballatMaiSector" col="col-5" label="En quin sector professional?" value={hasTreballatMaiSector} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setHasTreballatMaiSector} error={errors.hasTreballatMaiSector} />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="treballarasDurantCurs"
                                        name="treballarasDurantCurs"
                                        col="col-2"
                                        label="Treballaràs durant el curs?"
                                        obligatori={false}
                                        value={treballarasDurantCurs}
                                        handleChange={(e) => setTreballarasDurantCurs(e.target.value)} />

                                      {treballarasDurantCurs === "1" && <FormTextArea id="treballarasDurantCurs" name="treballarasDurantCurs" col="col-5" label="De què i en quin horar?" value={treballarasDurantCursHorari} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setTreballarasDurantCursHorari} error={errors.treballarasDurantCursHorari} />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="creusTreballarDificultaEstudis"
                                        name="creusTreballarDificultaEstudis"
                                        col="col-2"
                                        label="Creus que treballar et dificultarà els teus estudis?"
                                        obligatori={false}
                                        value={creusTreballarDificultaEstudis}
                                        handleChange={(e) => setCreusTreballarDificultaEstudis(e.target.value)} />

                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>


                </Dropdown>
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>
  );
}