import React, { useEffect, useState, useCallback, useId } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'react-slidedown/lib/slidedown.css'
import Dropdown from '../dropdown'
import { FormSelectYesNo, FormSelectYesNoFile, FormInputText, DateSelector, FormTextArea } from './formelements'
import { useDropzone } from 'react-dropzone'


export default function EnrolmentFormAuthorizations(props) {

  /* DADES MEDIQUES */
  const [authorizationsOpen, setAuthorizationsOpen] = useState(true);

  const toggleAuthorizationsOpen = () => {
    setAuthorizationsOpen(!authorizationsOpen);
  }

  const [authIntranet, setAuthIntranet ] = useState(false);
  const [authPublicacions, setAuthPublicacions ] = useState(false);
  const [authMaterial, setAuthMaterial ] = useState(false);
  const [authInternet, setAuthInternet ] = useState(false);
  const [authGDPR, setAuthGDPR] = useState(false);


  return (
    <div className="container">
      <div className="row">
        <div className="col col-12 ">
          <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">
            <div className="t-Region-header">
              <div className="t-Region-headerItems  t-Region-headerItems--controls">
                <button
                  className="t-Button t-Button--icon t-Button--hideShow"
                  type="button"
                  onClick={toggleAuthorizationsOpen}
                >
                  {authorizationsOpen && (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      fontSize="18"
                      className="t-Icon"
                    />
                  )}
                  {!authorizationsOpen && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      fontSize="18"
                      className="t-Icon"
                    />
                  )}
                </button>
              </div>
              <div className="t-Region-headerItems t-Region-headerItems--title">
                <h2 className="t-Region-title a-Collapsible-heading">
                  Autoritzacions
                </h2>
              </div>
              <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
            </div>
            <div className="t-Region-bodyWrap">
              <Dropdown open={authorizationsOpen}>
                <div className="t-Region-body a-Collapsible-content">
                  <div className="container">
                    <div className="row">
                      <div className="col col-12 ">
                        <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                          <div className="t-Region-bodyWrap">
                            <div className="t-Region-body">
                              <div className="container">
                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                      Autorització relativa a l’alumnat major de
                                      14 anys i del personal docent i no docent
                                      del centre: ús d’imatges, publicació de
                                      dades de caràcter personal i de material
                                      que elaboren. Curs {props.enguany}-
                                      {props.anyVinent}
                                    </h4>
                                    <p>
                                      El centre disposa d’espais de comunicació
                                      i difusió, inclosos els espais web (adreça
                                      o adreces web), on informa i fa difusió de
                                      les activitats escolars lectives,
                                      complementàries i extraescolars. En
                                      aquests espais es poden publicar
                                      imatges/veu en què aparegui,
                                      individualment o en grup, alumnat i
                                      personal docent que fa les activitats
                                      esmentades. El dret a la pròpia imatge és
                                      reconegut en l’article 18.1 de la
                                      Constitució espanyola i està regulat per
                                      la Llei orgànica 1/1982, de 5 de maig,
                                      sobre el dret a l’honor, a la intimitat
                                      personal i familiar i a la pròpia imatge,
                                      per la qual cosa la direcció d’aquest
                                      centre demana el consentiment per publicar
                                      fotografies i vídeos on surti alumnat i
                                      personal docent que hi sigui clarament
                                      identificable.
                                    </p>

                                    <p>
                                      Autoritzo mentre mantingui vinculació amb
                                      el centre
                                    </p>

                                    <p>
                                      1. Que la meva imatge/veu pugui sortir en
                                      fotografies i/o vídeos corresponents a
                                      activitats escolars lectives,
                                      complementàries i extraescolars
                                      organitzades pel centre i publicades en: -
                                      En webs del centre:
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <FormSelectYesNo
                                      id="authIntranet"
                                      name="authIntranet"
                                      col="col-4"
                                      label="Intranet amb accés restringit:"
                                      obligatori={true}
                                      value={authIntranet}
                                      handleChange={(e) =>
                                        setAuthIntranet(e.target.value)
                                      }
                                    />

                                    <FormSelectYesNo
                                      id="authPublicacions"
                                      name="authPublicacions"
                                      col="col-4"
                                      label="Publicacions del centre"
                                      obligatori={true}
                                      value={authPublicacions}
                                      handleChange={(e) => setAuthPublicacions(e.target.value) } />

                                    <FormSelectYesNo
                                      id="authInternet"
                                      name="authInternet"
                                      col="col-4"
                                      label="Internet amb accés no restringit"
                                      obligatori={true}
                                      value={authInternet}
                                      handleChange={(e) => setAuthInternet(e.target.value) } />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <p>
                                      2. Que el material que elabori pugui ser publicat en els espais de comunicació
                                      (blogs i espais web i revistes) del mateix centre amb la finalitat de desenvolupar
                                      l’activitat educativa:
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col col-12 ">

                                    <FormSelectYesNo
                                      id="authMaterial"
                                      name="authMaterial"
                                      col="col-4"
                                      label="Autoritzo"
                                      obligatori={true}
                                      value={authMaterial}
                                      handleChange={(e) => setAuthMaterial(e.target.value) } />
                                  </div>
                                </div>

                                      <br/>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                      Informació bàsica sobre protecció de dades
                                    </h4>
                                    <p>
                                      <b>Responsable del tractament:</b> Direcció del centre educatiu.<br/>
                                      <b>Finalitat:</b> Autorització difusió d’imatge/veu i material elaborat als espais de comunicació i difusió del centre.<br/>
                                      <b>Legitimació:</b> Consentiment de l’interessat o de la persona que ostenta la tutoria legal en cas de menors d’edat.<br/>
                                      <b>Destinataris:</b> Les dades no es comunicaran a tercers, excepte en els casos previstos per llei, o si ho heu consentit
                                      prèviament.<br />
                                      <b>Drets:</b> Accedir a les dades, rectificar-les, suprimir-les, oposar-se al tractament i sol·licitar-ne la limitació.<br />
                                      <b>Informació addicional:</b> Podeu consultar la informació addicional i detallada sobre protecció de dades a la pàgina:
                                      <ul>
                                        <li><a href="http://ensenyament.gencat.cat/ca/departament/proteccio-dades/informacio-addicional-tractaments/alumnes-centresdepartament.html" target="_blank">http://ensenyament.gencat.cat/ca/departament/proteccio-dades/informacio-addicional-tractaments/alumnes-centresdepartament.html</a> </li>
                                        <li><a href="http://ensenyament.gencat.cat/ca/departament/proteccio-dades/informacio addicional-tractaments/personalcentres-departament.html" target="_blank">http://ensenyament.gencat.cat/ca/departament/proteccio-dades/informacio addicional-tractaments/personalcentres-departament.html</a></li>
                                      </ul>
                                    <br/>

                                      <input type="checkbox" id="authGDPR" name="authGDPR" value={authGDPR} checked={authGDPR === "1" ? true : false } onChange={ (t) => { console.log('setAI',t.target);  setAuthGDPR(t.target.value === "0" ? "1" : "0") } } required/>
                                      He llegit la informació bàsica sobre protecció de dades i autoritzo el tractament de les meves dades personals.
                                    </p>

                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}