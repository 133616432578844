import React, { useState } from 'react';
import EnrolmentFormHeader from './header';
//import EnrolmentFormBreadcrumb from './enrolmentformbreadcrumb';
import EnrolmentFormPersonalDataId from './personaldataid';
import EnrolmentFormStudies from './studies';
import EnrolmentFormAuthorizations from './authorizations';

//<EnrolmentFormBreadcrumb />
export default function EnrolmentForm() {

  let enguany = new Date().getFullYear()
  let anyVinent = enguany + 1
  anyVinent = anyVinent.toString().slice(-2)

  const [dadesPersonals, setDadesPersonals] = useState()
  const [dadesEstudis, setDadesEstudis] = useState()

  const submitForm = (e) => {
    e.preventDefault();

    console.log('sending!')

    return false;
  }


  const validaDadesPersonals = () => {

      //TO-DO
      return true;
  }

  return (
    <form action="#" method="POST" onSubmit={submitForm}>

      <div class="t-Body">

        <EnrolmentFormHeader />

        <div className="row">
          <div className="col col-12 ">
            <div
              className="t-BreadcrumbRegion t-BreadcrumbRegion--showBreadcrumb t-BreadcrumbRegion--useBreadcrumbTitle">
              <div className="t-BreadcrumbRegion-body">

                <div className="t-BreadcrumbRegion-breadcrumb">
                  <ul className="t-Breadcrumb ">
                    <li className="t-Breadcrumb-item is-active">
                      <h1 className="t-Breadcrumb-label">Matrícula {enguany}/{anyVinent}</h1>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div className="t-Body-content" id="t_Body_content">
          <span id="SUCCESS_MESSAGE" className="apex-page-success u-hidden"></span>
          <span id="ERROR_MESSAGE" className="apex-page-error u-hidden"></span>

          <div className="t-Body-main" style={{ marginTop: "0px" }}>
            <div className="t-Body-title" id="t_Body_title" style={{ top: "40px" }}></div>
            <div className="t-Region">

              <div className="t-Body-content">
                <div className="t-Region-bodyWrap">
                  <div className="t-Region-body">
                    <EnrolmentFormPersonalDataId dadesPersonals={dadesPersonals} onChange={setDadesPersonals} />
                    <EnrolmentFormStudies dadesPersonals={dadesPersonals} dadesEstudis={dadesEstudis} onChange={setDadesEstudis} validaDadesPersonals={validaDadesPersonals} />
                    <EnrolmentFormAuthorizations dadesPersonals={dadesPersonals} enguany={enguany} anyVinent={anyVinent} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </form>
  );
}