import DatePicker, { registerLocale }  from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import ca from 'date-fns/locale/ca';
registerLocale('ca', ca)

export  const DateSelector = (props) => {

  /*
       minDate={props.minDate}
       maxDate={props.maxDate}
       */

   return (
     <DatePicker
       locale={ca}
       selected={props.selected}
       onChange={(d:Date) => props.onChange(d)}
       showMonthDropdown
       showYearDropdown
       dropdownMode="select"
       dateFormat="P"
       withPortal
     />
   );
};

export const FormSelectYesNo = (props) => {

return (<div className={ 'col ' + props.col}>

      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

        <div className="t-Form-labelContainer">
          <label for={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}{ 
            props.obligatori && (<span className="t-Form-required"> <span className="a-Icon">*</span> </span>)
          }</label>
        </div>

        <div className="t-Form-inputContainer">

          <div className="t-Form-itemWrapper">

            <div className="col col-6 ">
              <input type="radio" id={props.id + '_SI'} required={props.obligatori} name={props.name} value="1" onClick={props.handleChange} />
              <label for={props.id + '_SI'} id={props.id + '_LABEL_SI'} className="t-Form-label">Sí</label>
            </div>

            <div className="col col-6 ">
              <input type="radio" id={props.id + '_NO'} required={props.obligatori}  name={props.name} value="0" onClick={props.handleChange} />
              <label for={props.id + '_NO'} id={props.id + '_LABEL_NO'} className="t-Form-label">No</label>
            </div>
          </div>

          <span id="{props.id}_ERROR" className="a-Form-error">{props.error}</span>


        </div>

      </div>

    </div>)
}

export const FormInputText = (props) => {


return (
    <div className={ 'col ' + props.col}>
      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

        <div className="t-Form-labelContainer">
          <label for={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}:{ props.obligatori ? (<sup>*</sup>) : "" }</label>
        </div>

        <div className="t-Form-inputContainer">

          <div className="t-Form-itemWrapper">
            
            <input type="text" id={props.id} name={props.name} className="text_field"
            required={props.obligatori} defaultValue={props.value} size={props.size} maxlength={props.maxLength} onChange={ (t) => props.onChange(t.target.value) } />

          </div>

          { props.error && (<span id={props.id + '_ERROR'} className="a-Form-error">{props.error}</span>) }

        </div>
      </div>
    </div>
  )
}


export const FormSelectRadio = (props) => {

    const elements = props.elements

    let disabled = false 
      console.log('handle 0',props,disabled)

    if(props.disabled) { disabled = true }

      console.log('handle 11',disabled)

    const handle = (e,disabled) => {

      console.log('handle 1',disabled)
      if(!disabled)
      {
      console.log('handle 2',disabled)
       props.handleChange(e)  
      }

    }

  
    return (<div className="col col-12 ">
  
        <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">
  
          <div className="t-Form-labelContainer">
            <label for={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}</label>
          </div>
  
          <div className="t-Form-inputContainer">
  
            <div className="t-Form-itemWrapper">
  
              { elements.map( (element,index) =>  
              (<div className={'col ' + props.col} key={index}>
                  <input type="radio" id={element.id} name={props.name} disabled={disabled} value={element.id} onClick={ () => handle(element.id,disabled) } />
                  <label for={element.id} id={element.id+ '_LABEL'} className="t-Form-label">{element.value}</label>
                </div>) )}
  
            </div>
  
            <span id="{props.id}_ERROR" className="a-Form-error"></span>
  
          </div>
  
        </div>
  
      </div>)
  }

export const FormTextArea = (props) => {

return (
    <div className={ 'col ' + props.col}>
      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

        <div className="t-Form-labelContainer">
          <label for={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}:{ props.obligatori ? (<sup>*</sup>) : "" }</label>
        </div>

        <div className="t-Form-inputContainer">

          <div className="t-Form-itemWrapper">
            
            <textarea id={props.id} name={props.name} className="textarea" wrap="virtual"
            required={props.obligatori} defaultValue={props.value} rows={props.rows} cols={props.cols} maxlength={props.maxLength} onChange={ (t) => props.onChange(t.target.value) } />

          </div>

          { props.error && (<span id={props.id + '_ERROR'} className="a-Form-error">{props.error}</span>) }

        </div>
      </div>
    </div>
  )
}


export const FormSelectCicle = (props) => {


   let col = props.nouAlumne ?  "col-6" : "col-6"


  return (<div className="col col-12 ">

      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

        <div className="t-Form-labelContainer">
          <label for={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}</label>
        </div>

        <div className="t-Form-inputContainer">

          <div className="t-Form-itemWrapper">

            <div className={'col ' + col}>
              <input type="radio" id={props.id + '_CFGM'} name="CICLE" value="CFGM" onClick={props.handleChange} />
              <label for={props.id + '_CFGM'} id={props.id + '_LABEL'} className="t-Form-label">CFGM - Cicle Formatiu de Grau Mitjà</label>
            </div>

            <div className={'col ' + col}>
              <input type="radio" id={props.id + '_CFGS'} name="CICLE" value="CFGS" onClick={props.handleChange} />
              <label for={props.id + '_CFGS'} id={props.id + '_LABEL'} className="t-Form-label">CFGS - Cicle Formatiu de Grau Superior</label>
            </div>

{ props.nouAlumne && (
              <div className={'col ' + col}>
                <input type="radio" id={props.id + '_CE'} name="CICLE" value="CE" onClick={props.handleChange} />
                <label for={props.id + '_CE'} id={props.id + '_LABEL'} className="t-Form-label">Cursos Especialització</label>
              </div>)
}

{ props.nouAlumne && (
              <div className={'col ' + col}>
                <input type="radio" id={props.id + '_PFI'} name="CICLE" value="PFI" onClick={props.handleChange} />
                <label for={props.id + '_PFI'} id={props.id + '_LABEL'} className="t-Form-label">Programes de Formació i Inserció</label>
              </div>)
}

          </div>

          <span id={props.id + '_ERROR'} className="a-Form-error"></span>


        </div>

      </div>

    </div>)
}

export const FormSelectNouAlumne = (props) => {

  return (<div className="col col-6 ">

      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

        <div className="t-Form-labelContainer">
          <label for={props.id} id={props.id + '_LABEL'} className="t-Form-label">{props.label}</label>
        </div>

        <div className="t-Form-inputContainer">

          <div className="t-Form-itemWrapper">

            <div className="col col-2 ">
              <input type="radio" id={props.id + '_SI'} name="nouAlumne" value="1" onClick={props.handleChange} />
              <label for={props.id + '_SI'} id={props.id + '_LABEL_SI'} className="t-Form-label">Sí</label>
            </div>

            <div className="col col-2 ">
              <input type="radio" id={props.id + '_NO'} name="nouAlumne" value="0" onClick={props.handleChange} />
              <label for={props.id + '_NO'} id={props.id + '_LABEL_NO'} className="t-Form-label">No</label>
            </div>
          </div>

          <span id="{props.id}_ERROR" className="a-Form-error"></span>


        </div>

      </div>

    </div>)
}