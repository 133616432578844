import React from 'react';
//import './App.css';
import EnrolmentForm from './components/enrolmentform';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Footer = () => {

  return (
    < footer class="t-Footer" >
      <div className="t-Footer-body">
        <div className="t-Footer-content">
          <div id="divPeuLogo">
            <img src="assets/logo_generalitat_peu.png" alt="Generalitat de Catalunya" />
          </div>
          <div id="divPeuAvis">
            <p>Avís legal: D'acord amb l'article 17.1 de la Llei 19/2014, la ©Generalitat de Catalunya permet
              la reutilització de continguts i de les dades sempre que se'n citi la font i la data
              d'actualització, que no es desnaturalitzi la informació (article 8 de la Llei 37/2007) i també
              que no es contradigui amb una llicència específica.</p>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>
        <div className="t-Footer-apex">
          <div className="t-Footer-version"></div>
          <div className="t-Footer-customize"></div>
        </div>
        <div className="t-Footer-top" onClick={ () =>  window.scrollTo(0, 0) }>
          <span className="t-Footer-topButton" id="t_Footer_topButton" aria-label="Inici pàgina"
            title="Start of page"><FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon"  /></span>
        </div>
      </div>
</footer >)
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <EnrolmentForm/>
      </header>


      <Footer />
    </div>
  );
}

export default App;
