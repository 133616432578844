import { useState } from 'react'
import dadesbancaries from '../data/dadesbancaries.json'
let QUOTA_ASSEGURANCA = 1.12

export function calculQuota(curs, assignatures, dataNaixement, repetidor, materials, setMaterials ){

let nivell = "1" // TO-DO

let quota = {
             matricula: 0,
             asseguranca: 0,
             material: 0,
             formacio: 0,
             total: 0,
             dadesbancaries: dadesbancaries }


if(dataNaixement === undefined) return quota
if(curs === undefined) return quota
if(curs?.id === undefined) return quota

let temps= new Date()
temps.setFullYear(new Date().getFullYear() - 28)

const menor28 = dataNaixement.getTime() >= temps.getTime() ? true : false

  console.log("ASSIG",assignatures)

  const quotaAsseguranca = (menor28) => {

    if (menor28) {
      return QUOTA_ASSEGURANCA
    }
      
    return 0
  }

  const quotaMaterial = (curs) => {

    if (curs.material === undefined ) return 0

    if(materials.length === 0 ) 
    {
      let mats = []

      curs.material.map((m) => 
      { 
            mats.push({...m,checked: true })
      })

      setMaterials(mats)
    }


    let totalMaterials =  0

      materials.map((m) => 
      { 

          if(m.checked) totalMaterials += m.preu
            
      })

      
    return totalMaterials
  }

switch(curs.id)
{
     //CFGM de Cures auxiliars d’infermeria 
     case "CFPM1601": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignatures.length === 1 && /^FCT/.test(assignatures[0]) ) {

                              quota.matricula = 0;

                        } else{
                          // matricula
                          if( nivell === "1" ) {
                              quota.matricula = 80;
                          }
                          else {
                              quota.matricula = 50;
                          }
                          // repetidor
                          if( repetidor ) {
                              quota.matricula = 50;
                          }
                        }

                        quota.total += quota.matricula

                        // material
                        quota.material = 0
                        quota.total += quota.material

                        // total
                        return quota;

     //CFGM de Farmàcia i parafarmàcia
     case "CFPMSA20": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignatures.length === 1 && /^FCT/.test(assignatures[0]) ) {

                              quota.matricula = 0;

                        } else{
                          // matricula
                          if( assignatures.length <= 4 ) {
                              quota.matricula = 40;
                          }
                          else {
                              quota.matricula = 80;
                          }
                        }

                        quota.total += quota.matricula

                        // material
                        quota.material = 0
                        quota.total += quota.material

                        // total
                        return quota;

    // CFGM d’Emergències sanitàries
     case "CFPMSA30": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignatures.length === 1 && /^FCT/.test(assignatures[0]) ) {

                              quota.matricula = 0;

                        } else{
                          // matricula
                          if( assignatures.length <= 4 ) {
                              quota.matricula = 40;
                          }
                          else {
                              quota.matricula = 80;
                          }
                        }

                        quota.total += quota.matricula

                        // material
                        quota.material = 0
                        quota.total += quota.material

                        // total
                        return quota;

    //CFGM de Carrosseria
     case "CFPMTM20": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignatures.length === 1 && /^FCT/.test(assignatures[0]) ) {

                              quota.matricula = 0;

                        } else{
                          // matricula
                          if( assignatures.length <= 4 ) {
                              quota.matricula = 40;
                          }
                          else {
                              quota.matricula = 80;
                          }
                        }

                        quota.total += quota.matricula

                        // material
                        quota.material = 0
                        quota.total += quota.material

                        // total
                        return quota;
    //CFGM d’Electromecànica de Vehicles Automòbils
     case "CFPMTM10": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignatures.length === 1 && /^FCT/.test(assignatures[0]) ) {

                              quota.matricula = 0;

                        } else{
                          // matricula
                          if( assignatures.length <= 4 ) {
                              quota.matricula = 40;
                          }
                          else {
                              quota.matricula = 80;
                          }
                        }

                        quota.total += quota.matricula

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material

                        // total
                        return quota;

    //CFGM d’Electromecànica de Vehicles Automòbils, perfil professional d’Electromecànica de Vehicles Industrials
     case "CFPMTM12": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignatures.length === 1 && /^FCT/.test(assignatures[0]) ) {

                              quota.matricula = 0;

                        } else{
                          // matricula
                          if( assignatures.length <= 4 ) {
                              quota.matricula = 40;
                          }
                          else {
                              quota.matricula = menor28 ? 78.88 : 80;
                          }
                        }

                        quota.total += quota.matricula

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material

                        // total
                        return quota;
    //CFGS Projectes d’edificació (Inclou el perfil professional en rehabilitació i restauració d’edificis)
     case "CFPSEOB1": 
                        // assegurança
                        quota.asseguranca = quotaAsseguranca(menor28)
                        quota.total += quota.asseguranca
                        
                        if(assignatures.length === 1 && /^FCT/.test(assignatures[0]) ) {

                              quota.matricula = 0;

                        } else{

                          // matricula
                            //TO-DO curs complet o no
                              quota.matricula =  25 * assignatures.length
                          if(quota.matricula>360){
                              quota.matricula = 360
                          }
                        }
                        quota.total += quota.matricula

                        //despeses de formació
                        quota.formacio = 80
                        quota.total += quota.formacio


                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material

                        // total
                        return quota;


     default: return quota;
      
}

}

export const MostraQuota = (props)  => {

let quota = props.quota

const handleChangeRepetidor = (t) => {
  props.setRepetidor(!props.repetidor)

  if(props.materials)
  {
   // repetidors i segon any no paguen Electude
    let noPagaElectude = props.repetidor ? true : false ;

    let mat = [...props.materials]

     mat.filter(item => item.id === "Electude").map( (i) => i.checked = noPagaElectude )

     props.setMaterials(mat)
  }

}

const handleChangeMaterial = (t) => {

let mat = [...props.materials]

  mat.filter(item => item.id === t).map( (i) => i.checked = !i.checked )

  props.setMaterials(mat)
}

const isMaterialChecked = (id) => {

let mat = props.materials.filter(item => item.id === id).pop()

if(mat === undefined ) return false
return mat.checked
}

  return (
    <div className="col col-12">

      <div className="t-Form-labelContainer">
        <label className="t-Form-label"> Quota de pagament del curs escolar 2022-23 </label>
      </div>

      <div className="col quota">


        <div className="quotaTitol">Curs: {props.curs.id} - {props.curs.value}</div>

        <div className="quotaDadesBancaries">

          <div><span className="quotaNomCamp">Entitat bancària:</span> {quota.dadesbancaries.entitat}</div>

          <div> <span className="quotaNomCamp">nº IBAN:</span> {quota.dadesbancaries.IBAN}</div>

          <div className="quotaDadesPagamentCalendari"><span className="quotaNomCamp">Condicions:</span> {quota.dadesbancaries.condicions[props.curs.condicions]}</div>

        </div>

        <label> Conceptes </label>

        <table className="quota table">

          <tr>
            <th className="quotaNomCamp" colspan="2">Assegurança Escolar Obligatòria: </th>
          </tr>

          <tr>
            { quota.menor28 && (<td scope="col">Alumnat menor de 28 anys</td>)}
            {!quota.menor28 && (<td scope="col">Alumnat de 28 anys i major</td>)}
            <td>{(quota.asseguranca).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td>
          </tr>

          <tr>
            <td colspan="2"><input type="checkbox" id="repetidor" name="repetidor" value="repetidor" checked={props.repetidor} onChange={(e) => handleChangeRepetidor(e)} ></input>
             Alumnat de 2n any / Repetidor:</td>
          </tr>

          <tr>
            <td>Matriculació al curs:</td>
            <td>{(quota.matricula).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
            </td>
          </tr>

          { quota.formacio > 0 && (<tr><td className="quotaNomCamp">Despeses de formació:</td><td>{(quota.formacio).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td></tr>)}

          {!props.curs.material && (<tr><td className="quotaNomCamp">Material específic del cicle:</td><td>0&euro;</td> </tr>)}
          {props.curs.material && (<tr><th colspan="2" className="quotaNomCamp">Material específic del cicle:</th></tr>)}

          {props.curs.material && props.curs.material.map((m, p) =>
          (<tr>
            <td>
            <input type="checkbox" id={m.id} name={m.id} value={m.preu} checked={isMaterialChecked(m.id)} onChange={(e) => handleChangeMaterial(e.target.id)} />
            {m.id}: 
            </td>
            <td>
            {m.preu}&euro;
          </td></tr>)
          )}

          <tr><th className="quotaNomCamp" colspan="2"> Total a abonar a compte:</th></tr>

          <tr>
          { quota.menor28 && (<td>Alumnat menor de 28 anys</td>)}
          {!quota.menor28 && (<td>Alumnat de 28 anys i major:</td>)}
           <td className="quotaTotal"> {(quota.total).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td>
          </tr>

        </table>
      </div>

    </div >
  )
  
 }

