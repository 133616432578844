import React from 'react';

 export default function EnrolmentFormHeader()
 {
    return (
      <header>

        <div class="container">
          <div class="row">
            <div class="col col-12 " style={{ backgroundColor: '#333', padding: '2px 0 0 15px' }}>
              <a href="https://inscripcions.iescastellarnau.cat/" class="t-Header-logo-link">
                <img src="img/logo_bn_castellarnau.png" alt="Logo Institut Castellarnau" style={{ margin: '0px' }} /></a>
            </div>
          </div>
        </div>

      </header>
    );
}