import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { MostraQuota, calculQuota } from '../quotes'
import Dropdown from '../dropdown';
import { FormInputText, FormSelectRadio, FormSelectCicle, FormSelectNouAlumne } from './formelements';
import assignatures from '../../data/assignatures.json'
import families from '../../data/families.json'


const TriaAssignatures = (props) => {

 console.log('CURS_ID',props.curs,props.curs.id);

  const themes = assignatures[props.curs.id]

  const [isCheckAll, setIsCheckAll] = useState([false, false, false]); // level = 0,1,2
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAllLevel = (level) => {

    let newA = [...isCheckAll];

    newA[level] = !newA[level];

    setIsCheckAll(newA);

    let allList = []

    if (!isCheckAll[level]) {

      themes.map((theme) => theme.subjects.filter(item => item.level === level).map((subject) => { return allList.push(subject.id) }))

    }

    setIsCheck(allList)
  };

  const handleClickOnSubject = (id, checked) => {

    if (checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
    else {
      setIsCheck([...isCheck, id])
    }

  }


  useEffect((e) => {

    props.handleChange(isCheck)

  }, [isCheck])



  return (<div className="col col-12">

    <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

      <div className="t-Form-labelContainer">
        <label for={props.id} id={props.id + '_LABEL'} className="t-Form-label">Tria d'assignatures</label>
      </div>

      <div className="t-Form-inputContainer">

        <div className="t-Form-itemWrapper">
          <div className="col col-4 "> &nbsp;</div>
          <div className="col col-4 ">
            <label className="t-Form-label">Tria totes les assignatures del curs:</label>
            <table border="0" className="subjectsTable">

              <tr className="level_1" onClick={() => handleSelectAllLevel(1)}>
                <td>
                  <label className="t-Form-label">1r curs tot el curs</label>
                </td>
              </tr>

              <tr className="level_2" onClick={() => handleSelectAllLevel(2)}>
                <td>
                  <label className="t-Form-label">2n curs tot el curs</label>
                </td>
              </tr>
            </table>
          </div>

          <table border="0" className="subjectsTable">

            {/*
            <tr>
                <th></th>
                <th className="">Codi</th>
                <th className="">Assignatura</th>
                <th className="">Hores</th>
                <th className="">Codi curs</th>
            </tr>
*/}

            {themes.map((theme, index) =>
            (
              <React.Fragment>
                <tr>
                  <th colspan="1">
                  </th>
                  <th colspan="2">
                    <label for={theme.id} id={theme.id + '_LABEL'} className="t-Form-label"><b>{theme.name}</b></label>
                  </th>
                </tr>
                {theme.subjects.map((subject, index) =>
                (<tr key={index} className={'level_' + subject.level} onClick={() => handleClickOnSubject(subject.id, isCheck.includes(subject.id))}>
                  <td><input type="checkbox" id={subject.id} name="familia" value={subject.id} checked={isCheck.includes(subject.id)} readOnly /></td>
                  <td for={theme.id} id={theme.id + '_LABEL'} className="">{subject.id.split(".").pop()}</td>
                  <td for={theme.id} id={theme.id + '_LABEL'} className="">{subject.name}</td>
                  {/*<td for={theme.id} id={theme.id + '_LABEL'} className="">{subject.hours}</td>
                  <td for={theme.id} id={theme.id + '_LABEL'} className="">{subject.assign}</td>*/}
                </tr>))}
              </React.Fragment>
            ))}

          </table>

        </div>

        <span id="{props.id}_ERROR" className="a-Form-error"></span>

      </div>

    </div>

  </div>)
}




const TriaCurs = (props) => {

  let fam = families[props.cicle].families.filter(f => f.id === props.familia).pop();

  let disabled

  switch(props.cicle)
  {
     case 'CE': disabled = true; break;
     case 'PFI': disabled = true; break;
     default: disabled = false;
  }

  return (<FormSelectRadio elements={fam.cursos} col=" col-12" name="curs" disabled={disabled} label={props.label} handleChange={props.handleChange} />)

}

const CFGS = (props) => {

  return (<FormSelectRadio elements={families.CFGS.families} col=" col-3" name="familia" label={props.label} handleChange={props.handleChange} />)

}

const CFGM = (props) => {

  return (<FormSelectRadio elements={families.CFGM.families} col=" col-3" name="familia" label={props.label} handleChange={props.handleChange} />)

}

const CE = (props) => {

  return (<FormSelectRadio elements={families.CE.families} col=" col-3" name="familia" label={props.label} handleChange={props.handleChange} />)

}

const PFI = (props) => {

  return (<FormSelectRadio elements={families.PFI.families} col=" col-3" name="familia" label={props.label} handleChange={props.handleChange} />)

}


export default function EnrolmentFormStudies(props) {

  const [cicle, setCicle] = useState();
  const [nouAlumne, setNouAlumne] = useState();
  const [nPreinscripcio, setNPreinscripcio] = useState('');
  const [familia, setFamilia] = useState();
  const [curs, setCurs] = useState();
  const [quota, setQuota] = useState();
  const [dadesInscripcioOpen, setDadesInscripcioOpen] = useState(true);
  const [dadesQuotaOpen, setDadesQuotaOpen] = useState(true);
  const [assignatures, setAssignatures] = useState([]);
  const [repetidor , setRepetidor ] = useState(false)
  const [materials, setMaterials] = useState([])

  const dataNaixement = props.dadesPersonals?.dataNaixement;

  // canvis en dades quota, recaulculem
  useEffect(() => {

    setQuota(calculQuota(curs,assignatures,dataNaixement,repetidor,materials,setMaterials));

  }, [assignatures,repetidor,dataNaixement,curs,materials]);


  const handleCanviCicle = (t) => {
    setCicle(t.target.value)
    setFamilia(null)
    setCurs(null)
    setAssignatures([])
    setMaterials([])
  }

  const handleCanviNouAlumne = (t) => {

    if(props.validaDadesPersonals())
    {

    setNouAlumne(t.target.value === "1" ? true : false)
    setNPreinscripcio('')
    setCicle(null)
    setFamilia(null)
    setCurs(null)
    setAssignatures([])
    setMaterials([])
    }
  }

  const handleCanviFamilia = (f) => {
    setFamilia(f)
    setCurs(null)
    setAssignatures([])
    setMaterials([])
  }

  const handleCanviCurs = (c) => {
    let fam = families[cicle].families.filter(f => f.id === familia).pop();
    let cur = fam.cursos.filter(fc => fc.id === c).pop();
    setCurs(cur)
    setAssignatures([])
    setMaterials([])
  }

  const handleCanviAssignatures = (a) => {
    console.log('assignatures', a)
    setAssignatures(a)
  }

  const toggleDadesInscripcioOpen = () => {
    setDadesInscripcioOpen(!dadesInscripcioOpen)
  }

  const toggleDadesQuotaOpen = () => {
    setDadesQuotaOpen(!dadesQuotaOpen)
  }


  // condicions per mostrar families 
  let mostraFamilies = (cicle && nouAlumne !== undefined) ? true : false

  // condicions per mostrar cicles
  let mostraSelectCicle = (nouAlumne !== undefined && (nouAlumne === false || (nouAlumne === true && nPreinscripcio.length > 0))) ? true : false

  return (
            <div className="container">
              <div className="row">
                <div className="col col-12 ">
                  <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">

                    <div className="t-Region-header">
                      <div className="t-Region-headerItems  t-Region-headerItems--controls">
                        <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={() => toggleDadesInscripcioOpen()}>
                          {dadesInscripcioOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                          {!dadesInscripcioOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                        </button>
                      </div>
                      <div className="t-Region-headerItems t-Region-headerItems--title">
                        <h2 className="t-Region-title a-Collapsible-heading">Inscripció</h2>
                      </div>
                      <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
                    </div>

                    <Dropdown open={dadesInscripcioOpen}>
                      <div className="t-Region-bodyWrap">
                        <div className="t-Region-body a-Collapsible-content">

                          <div className="container">
                            <div className="row">
                              <div className="col col-12 ">
                                <div
                                  className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                                  <div className="t-Region-bodyWrap">
                                    <div className="t-Region-body">
                                      <div className="container">
                                        <div className="row">

                                          <FormSelectNouAlumne id="nouAlumne" label="Ets alumne/a nou/nova al nostre centre?" handleChange={handleCanviNouAlumne} />

                                          {nouAlumne && (<FormInputText id="ninscripcio" name="inscripcio" label="nº de preinscripció" value={nPreinscripcio} onChange={setNPreinscripcio} size="20" maxLength="15" />)}

                                          {mostraSelectCicle && (<FormSelectCicle id="cicle" label="Nivell d'estudis:" nouAlumne={nouAlumne?true:false} handleChange={handleCanviCicle} />)}

                                          {mostraFamilies && cicle === "CFGM" && (<CFGM label="Família professional" handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && cicle === "CFGS" && (<CFGS label="Família professional" handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && cicle === "CE" && (<CE label="Família professional" handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && cicle === "PFI" && (<PFI label="Família professional" handleChange={handleCanviFamilia} />)}

                                          {mostraFamilies && familia && (<TriaCurs cicle={cicle} familia={familia} label="Tria curs" handleChange={handleCanviCurs} />)}

                                          {mostraFamilies && familia && curs && (<TriaAssignatures curs={curs} handleChange={handleCanviAssignatures} />)}

                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </Dropdown>

                  </div>
                </div>
              </div>

            {assignatures.length > 0 && (
                <div className="container">
                  <div className="row">
                    <div className="col col-12 ">
                      <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">

                        <div className="t-Region-header">
                          <div className="t-Region-headerItems  t-Region-headerItems--controls">
                            <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={() => toggleDadesQuotaOpen()}>
                              {dadesQuotaOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                              {!dadesQuotaOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                            </button>
                          </div>
                          <div className="t-Region-headerItems t-Region-headerItems--title">
                            <h2 className="t-Region-title a-Collapsible-heading">Quota d'inscripció</h2>
                          </div>
                          <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
                        </div>


                        <Dropdown open={dadesQuotaOpen}>
                          <div className="t-Region-bodyWrap">
                            <div className="t-Region-body a-Collapsible-content">

                              <div className="container">
                                <div className="row">
                                  <div className="col col-12 ">
                                    <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                                      <div className="t-Region-bodyWrap">
                                        <div className="t-Region-body">
                                          <div className="container">
                                            <div className="row">


                                              <MostraQuota curs={curs} repetidor={repetidor} setRepetidor={setRepetidor} quota={quota} materials={materials} setMaterials={setMaterials} />

                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>
                        </Dropdown>

                      </div>

                    </div>
                  </div>

                </div>)
              }
            </div>


  );
}